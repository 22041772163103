*, *:before, *:after, *:focus, *:active {
	box-sizing: border-box;
    outline: none !important;
}

html {
    height: 100%;
    font-size: 14px;
    @media @laptop {
        font-size: 14px;
    }
}

body {
    font-family: 'Roboto', Verdana, sans-serif;
    font-size: 1rem;
    height: 100%;
    color: black;
}

#wrapper {
    background: url('../images/wrapper-bg.png');
    background-repeat: no-repeat;
    background-position: top right;
}

#work {
    scroll-behavior: smooth;
}

img {
    max-width: 100%;
}

a {
    text-decoration: none;
}

p {
    text-align: justify;
    margin-bottom: 10px;
}

header {
    min-height: 55px;
    background: @dark;
    padding: 0 90px;
    @media @mobile {
        padding: 0;
    }
}

footer {
    color: white;
    min-height: 200px;
    margin-top: 40px;
    background: #292728;
    padding: 38px 105px 20px;
    @media @laptop {
        padding: 38px 80px 20px;
    }
    @media @mobile {
        padding: 20px 0;
    }
}