.logo {
    @media @mobile {
        display: none;
        height: 40px;
        margin-top: 6px;
    }
}

.logoResize {
    margin-top: 5px;
    height: 45px;
}

.addressMobile {
    font-size: 0.85rem;
    color: white;
    text-align: center;
    padding-top: 2px;
    margin-bottom: 0;
}

.phone {
    font-family: 'Roboto';
    font-size: 1.57rem;
    text-align: left;
    font-weight: bold;
    color: @orange;
    margin-top: 15px;
    @media @mobile {
        font-size: 1.28rem;
        text-align: center;
    }
    &_header {
        text-align: right;
        margin-top: 5px;
        @media @mobile {
            display: none;
        }
    }
}

.buttonMenu {
    margin-top: 15px;
    float:  right;
}

.address {
    font-family: 'Roboto';
    padding-top: 31px;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 20px;
    color: @orange;
    text-align: right;
    margin: 0;
    height: 20px;
}

.phoneFirst {
    font-family: 'a_PlakatCmpl';
    padding-top: 29px;
    margin: 0;
    font-size: 1.57rem;
    font-style: normal;
    line-height: 20px;
    text-align: right;
    color: @orange;
    @media @mobile {
        font-size: 1.28rem;
        padding-top: 15px;
    }
}

/* Header */
.headerFixed {
    @media @desktop {
        position: fixed;
        z-index: 9999;
        max-height: 70px;
        top: 0;
        left: 0;
        width: 100%;
        animation-name: animHeader;
        animation-duration: 1s;
    }
}

@keyframes animHeader {
    0% {
        opacity: 0;
        top: -127px;
    }
    100% {
        opacity: 1;
        top: 0;
    }
}

/* TOPMENU */
.topmenu {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 9px;
    @media @mobile {
        position: absolute;
		width: 60%;
		height: 568px;
		background-color: rgba(0, 0, 0, .8);
		margin: 0;
        top: 0;
        left: 0;
		z-index: 9999;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		transform: translateX(-100%);
        padding-left: 10px;
        padding-right: 10px;
    }
    &_active {
        transform: translateX(0);
    }
}

.topmenu__item {
    @media @mobile {
        background: black;
        margin: 5px 0;
        width: 100%;
        height: 45px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }
}

.topmenu__link {
    color: white;
    font-size: 1rem;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    line-height: 20px;
    text-transform: uppercase;
    &:hover {
        color: @orange;
    }
    @media @mobile {
        padding-left: 15px;
        height: 100%;
        color: @orange;
        .center();
    }
}

/* PROMO блок */
.promo {
    background: url('../images/promo.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    height: 644px; 
    margin-bottom: 75px;
    overflow: visible;
    @media @mobile {
        background: url('../images/mobile-promo.jpg');
        background-repeat: no-repeat;
        background-size: contain;
        height: 425px; 
        margin-bottom: 20px;
    }
}

.promo__block {
    display: block;
    margin: 0 auto;
    max-width: 887px;
    height: 685px;
    background: url('../images/promo-bg.png');
    background-size: contain;
    padding-top: 68px;
    @media @mobile {
        background: url('../images/mobile-promo-bg.png');
        background-size: cover;
        background-position: center;
        padding-top: 35px;
        height: 425px;
    }
}

.promo__logo {
    height: 85px;
    display: block;
    margin: 0 auto;
    @media @mobile {
        height: 51px;
    }
}

.promo__slogan {
    font-family: 'a_PlakatCmpl';
    text-align: center;
    margin: 0;
    &_first {
        padding: 39px 65px 0;
        font-size: 2.57rem;
        line-height: 40px;
        text-transform: uppercase;
        color: @orange;
        margin: 0;
        @media @mobile {
            padding: 34px 15px 0;
            font-size: 1.78rem;
            line-height: 30px;
        }
    }
    &_second {
        padding: 65px 150px 0;
        font-size: 2.5rem;
        line-height: 35px;
        text-align: center;
        color: #FFFFFF;
        @media @mobile {
            padding: 40px 15px 0;
            font-size: 1.43rem;
            line-height: 21.5px;
        }
    }
    &_third {
        font-family: 'Roboto';
        padding: 71px 180px 0;
        font-style: normal;
        font-weight: bold;
        font-size: 2.5rem;
        line-height: 36px;
        text-transform: uppercase;
        color: @orange;
        margin: 0;
        @media @mobile {
            padding: 40px 15px 0;
            font-size: 1.43rem;
            line-height: 21.5px;
        }
    }
}

/* Блок преимуществ */
.advantage__targetArrow {
    display: block;
    margin: 35px auto 0;
    @media @mobile {
        margin-top: 20px;
    }
}

.advantage__title {
    font-family: 'a_PlakatCmpl';
    font-size: 2.57rem;
    margin-top: 25px;
    line-height: 35px;
    text-align: center;
    @media @mobile {
        font-size: 1.78rem;
    }
}

.advantage__signature {
    font-family: 'Circe';
    font-style: normal;
    font-weight: normal;
    font-size: 1.14rem;
    line-height: 24px;
    text-align: center;
    color: #000000;
    margin: 10px 0 0 0;
}

.advantage__num {
    background: url('../images/brackets.svg');
    background-repeat: no-repeat;
    font-family: 'Roboto';
    font-weight: bold;
    display: block;
    font-size: 100px;
    width: 95px;
    height: 112px;
    margin: 0 auto;
    position: relative;
    z-index: 99;
    .center();
    @media @mobile {
        font-size: 80px;
        width: 71px;
        height: 83px;
        background: url('../images/mobile-brackets.svg');
    }
}

/* Слоганы */
.advantage__slogan {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 1.79rem;
    text-align: left;
    position: relative;
    z-index: 99;
    &_first {
        font-size: 1.79rem;
        line-height: 30px;
        text-transform: uppercase;
        @media @mobile {
            font-size: 1.29rem;
            line-height: 25px;
        }
    }
    &_second {
        font-size: 1.1rem;
        line-height: 29px;
        @media @mobile {
            font-size: 1.29rem;
            line-height: 20px;
        }
    }
    &_third {
        font-size: 1.1rem;
        line-height: 20px;
        color: #FE9418;
    }
}

.advantage__img {
    position: relative;
    z-index: 99;
}

/* Геометрические фигуры */
.advantage__geometry {
    position: relative;
    overflow: visible;
}

.advantage__triangle {
    position: absolute;
    
    top: -150px;
    left: 150px;
    @media @mobile {
        width: 150px;
        top: -49px;
        left: 214px;
    }
}

.advantage__triangle2 {
    position: absolute;
    z-index: 9;
    top: -40px;
    left: -10px;
}

.advantage__square {
    position: absolute;
    z-index: 9;
    top: -40px;
    left: 140px;
}

/* Блок окупаемости */
.profit {
    margin-top: 158px;
    min-height: 540px;
    background: url('../images/iron.jpg');
    padding: 0 135px;
    @media @mobile {
        margin-top: 0;
        padding: 0 15px;
    }
}

.profit__poster {
    font-family: 'a_PlakatCmpl';
    background: @orange;
    width: 708px;
    height: 156px;
    display: block;
    margin: 0 auto;
    position: relative;
    top: -87px;
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.25);
    padding-top: 34px;
    position: relative;
    z-index: 9;
    @media @mobile {
        padding: 20px 20px 0 20px;
        height: 174px;
        top: 0;
        left: -15px;
        width: ~"calc(100% + 30px)";
    }
}

.profit__call {
    font-size: 2.5rem;
    line-height: 35px;
    text-align: center;
    @media @mobile {
        font-size: 1.78rem;
        margin-bottom: 5px;
    }
}

.profit__money {
    font-size: 35px;
    line-height: 35px;
    text-align: center;
    color: white;
    font-size: 2.5rem;
    text-transform: uppercase;
    margin-bottom: 0;
    @media @mobile {
        font-size: 1.78rem;
    }
}

.profit__angle {
    position: absolute;
    top: -14px;
    right: -14px;
    z-index: 99;
    &_right {
        left: -14px;
        transform: rotate(0.75turn);
    }
    @media @mobile {
        display: none;
    }
}

.profit__arrow {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    & img {
        @media @mobile {
            transform: rotate(90deg);
        }
    }
}

.profit__slogan {
    color: white;
    text-transform: uppercase;
    background: url('../images/profit-brackets.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    height: 189px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media @mobile {
        background: url('../images/mobile-profit-brackets.png');
        background-size: cover;
        background-repeat: no-repeat;
        height: 289px;
        padding: 0 20px;
        
    }
    &_mobile {
        @media @mobile {
            background: url('../images/mobile-profit-brackets-2.png');
            background-size: cover;
            background-repeat: no-repeat;
            height: 191px;
            padding: 0 20px;
            
        }
    }
}

.profit__textSmall {
    font-size: 1.5rem;
    text-align: center;
}

.profit__textMiddle {
    font-size: 2rem;
    text-align: center;

}

.profit__textBig {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
}

.profit__signature {
    margin-top: 60px;
    font-family: 'a_PlakatCmpl';
    color: white;
    font-size: 2.5rem;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media @mobile {
        margin-top: 48px;
        font-size: 1.78rem;
        display: block;
        & p {
            text-align: center;
        }
    }
}

/* Блок с картой Яндекс*/
.mapbox {
    @media @desktop {
        height: 650px;
    }
}

.mapbox__title {
    margin-top: 94px;
    font-family: 'a_PlakatCmpl';
    font-size: 2.5rem;
    text-align: center;
    position: relative;
    z-index: 999;
    @media @mobile {
        font-size: 1.78rem;
        margin-top: 48px;
        padding: 0 10px;
    }
}

.mapbox__slogan {
    color: @orange;
    font-size: 1.78rem;
    font-weight: bold;
    text-align: center;
    position: relative;
    z-index: 999;
    @media @mobile {
        font-size: 1.14rem;
        padding: 0 40px;
    }
}

.mapbox__map {
    width: 1040px;
    display: block;
    margin: 0 auto;
    position: relative;
    z-index: 1;
    top: -90px;
    @media @mobile {
        display: none;
    }
    &_mobile {
        @media @desktop {
            display: none;
        }
        @media @mobile {
            display: block;
            top: -44px;
        }
    }
}

/* Предложение */
.offer {
    overflow: hidden;
}

.offer__slogan {
    color: white;
    height: 189px;
    width: 656px;
    background: url('../images/offer-brackets.png');
    padding: 21px 24px;
    display: block;
    margin: 40px auto 0;
    position: relative;
    z-index: 9;
    @media @mobile {
        background: url('../images/offer-brackets-mobile.png');
        background-position: center;
        background-repeat: no-repeat;
        padding: 21px 28px;
        width: 100%;
        height: 204px;
    }
}

.offer__subSlogan {
    background: #3A3737;
    height: 100%;
    padding: 20px 30px;
    & p {
        font-family: 'a_PlakatCmpl';
        font-size: 2.14rem;
        text-align: center;   
    }
    @media @mobile {
        padding: 20px 14px;
        & p {
            font-size: 1.78rem;  
            line-height: 30px;
        }
    }
}

.offer__textFirst {
    font-size: 1.78rem;
    @media @mobile {
        margin-bottom: 5px;
    }
}

.offer__free {
    padding-top: 140px;
    margin: 0 auto;
    position: relative;
    top: -94px;
    z-index: 1;
    @media @desktop {
        .crane();
    }
    @media @mobile {
        padding: 120px 0 20px;
        margin-left: 15px;
        margin-right: 15px;
        background: #F6F6F6;
    }
}

.offer__crane {
    @media @desktop {
        display: none;
    }
    position: absolute;
    top: -20px;
    left: 130px;
}

.offer__text {
    font-family: 'a_PlakatCmpl';
    font-size: 2.5rem;
    text-align: center;
    @media @mobile {
        font-size: 1.78rem;
        line-height: 25px;
    }
    &_phone {
        padding: 0 40px;
    }
}

.offer__signature {
    text-align: center;
    font-size: 1.42rem;
    &_last {
        @media @mobile {
            display: none;
        }
    }
    @media @mobile {
        font-size: 1.14rem;
        line-height: 18.5px;
        padding: 0 50px;
    }
}

/* Блок о сайте */
.sitebox {
    background: black;
    padding: 0 57px;
    @media @mobile {
        padding: 0;
        margin-right: -0.75rem;
        margin-left: -0.75rem;
    }
}

.sitebox__content {
    background-blend-mode: multiply;
    background-image: url('../images/site.jpg');
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: rgba(0, 0, 0, 0.8);
    height: 428px;
    padding-top: 57px;
    @media @mobile {
        padding-top: 20px;
    }
}

.sitebox__slogan {
    color: white;
    margin-top: 0 !important;
    @media @mobile {
        width: 100%;
    }
}

.sitebox__text {
    @media @mobile {
        display: none;
    }
    &_mobile {
        font-family: 'a_PlakatCmpl';
        color: @orange;
        display: block;
        font-size: 1.78rem;
        font-weight: bold;
        padding: 0 40px;
        line-height: 30px;
        text-align: center;
    }
    &_mobile {
        @media @desktop {
            display: none;
        }
    }
}

.sitebox__row {
    height: 245px;
    @media @mobile {
        height: 100%;
    }
}

/* Описание */
.sitebox__desc {
    background: white;
    padding: 30px;
    position: relative;
    top: -70px;
    left: 30px;
    @media @mobile {
        left: -20px;
        padding: 30px 20px 30px 30px;
    } 
}

.sitebox__title {
    font-size: 1.64rem;
    font-weight: 900;
}

.sitebox__list {
    padding-left: 30px;
    list-style-type: '— ';
}

.sitebox__item {
    margin-top: 10px;
    font-weight: bold;
    font-size: 1.25rem;
}

.sitebox__img {
    position: relative;
    top: -110px;
    @media @mobile {
        top: -70px;
    }
}

.sitebox__sigBox {
    margin-top: 28px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.sitebox__signature {
    font-family: 'a_PlakatCmpl';
    font-size: 2.1rem;
    color: #FE9418;
    margin-bottom: 0;
    line-height: 30px;
    @media @mobile {
        font-size: 1.78rem;
    }
}

.sitebox__arrow {
    margin-left: 32px;
    @media @mobile {
        margin-left: 0;
        position: relative;
        left: 20px;
    }
}

/* Главный слоган */
.mainSlogan {
    background: url('../images/slogan-brackets.png');
    background-repeat: no-repeat;
    background-position: center;
    width: 858px;
    height: 227px;
    margin: 77px auto 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &_main {
        @media @mobile {
            background: url('../images/slogan-brackets-main-mobile.png') !important;
            margin-top: 10px;
            background-repeat: no-repeat !important;
            background-position: center !important;
            height: 375px
        }
    }
    & p {
        font-family: 'a_PlakatCmpl';
        font-size: 1.8rem;
        @media @mobile {
            font-size: 1.78rem;
            padding: 0 20px;
            line-height: 30px;
            text-align: center;
            margin-bottom: 0;
        }

    }
    @media @mobile {
        background: url('../images/slogan-brackets-mobile.png');
        background-repeat: no-repeat;
        background-position: center;
        padding: 0 30px;
        width: 100%;
    }
}

/* Платеж */
.payment {
    margin-top: 81px;
    background: url('../images/iron.jpg');
    min-height: 359px;
    padding-bottom: 60px;
}

.payment__pay {
    width: 514px;
    height: 137px;
    padding-top: 15px;
    background: @orange;
    display: block;
    margin: 0 auto;
    position: relative;
    top: -15px;
    font-family: 'a_PlakatCmpl';
    font-size: 2.58rem;
    text-align: center;
    & p {
        text-align: center;
        @media @mobile {
            font-size: 2.14rem;
            padding: 0 40px;
            line-height: 32px;
        }&:last-child {
            @media @mobile {
                margin-top: 15px;
            }
        }
    }
    @media @mobile {
        padding-top: 25px;
        height: 189px;
        width: ~"calc(100% - 30px)";
        margin: 15px;
    }
}

.payment__signature {
    width: 688px;
    min-height: 100px;
    display: block;
    margin: 60px auto 0;
    padding: 20px;
    position: relative;
    color: white;
    font-family: 'a_PlakatCmpl';
    font-size: 2.58rem;
    & p {
        text-align: center;
    }
    @media @mobile {
        font-size: 1.78rem;
        line-height: 30px;
        margin-top: 33px;
        width: ~"calc(100% - 60px)";
        margin-right: 30px;
        margin-left: 30px;
    }
}

.payment__angle {
    position: absolute;
    &_1 {
        top: 0;
        left: 0;
        transform: rotate(90deg);
    }
    &_2 {
        top: 0;
        right: 0;
        transform: rotate(180deg);
    }
    &_3 {
        bottom: 0;
        left: 0;
        transform: rotate(0deg);
    }
    &_4 {
        bottom: 0;
        right: 0;
        transform: rotate(270deg);
    }
}

/* Проверьте город */
.checkCity {
    overflow: hidden;
}

.checkCity__title {
    font-family: 'a_PlakatCmpl';
    font-size: 2.58rem;
    text-align: center;
    @media @mobile {
        font-size: 1.78rem;
        padding: 0 15px;
    }
}

.checkCity__poster {
    position: relative;
    z-index: 1;
    margin: 66px auto 0;
    padding-top: 45px;
    padding-bottom: 60px; 
    @media @desktop {
        .crane();
    }
    @media @mobile {
        background: #F6F6F6;
        width: ~"calc(100% - 30px)";
        margin-right:  15px;
        margin-left: 15px;
    }
}

.checkCity__crane {
    position: absolute;
    top: 70px;
    left: 110px;
    @media @desktop {
        display: none;
    }
}

/* FOOTER */
.footer__title {
    text-transform: uppercase;
    color: @orange;
    font-size: 1.28rem;
    @media @mobile {
        margin-top: 20px;
    }
}

.footer__menu {
    padding-left: 0;
    margin-top: 30px;
    @media @mobile {
        margin-top: 15px;
    }
}

.footer__item {
    margin-top: 10px;
    font-size: 1.28rem;
}

.footer__link {
    color: white;
    font-size: 1.28rem;
    &:hover {
        color: @orange;
    }
}

.footer__signature {
    color: @orange;
    @media @mobile {
        margin-top: 15px;
        text-align: center;
    }
}

.footer__logo {
    display: block;
    margin: 0 auto;
    @media @mobile {
        margin-top: 30px;
        padding: 0 9.5px;
    }
}

.footer__aimart {
    margin-top: 20px;
    float: right;
    @media @mobile {
        width: 100%;
        margin: 40px auto 0;
        .center();
    }
}

.footer__copyRight {
    font-size: 0.71rem;
    line-height: 18px;
    margin-bottom: 0;
    @media @mobile {
        padding: 0 9.5px;
    }
}

.cityBox {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    @media @mobile {
        align-items: center;
    }
}

.cityBox__link {
    padding: 5px 0;
    display: inline-block;
    text-align: left;
    color: #FE8A17;
    @media @mobile {
        padding: 5px 10px;
        margin-top: 10px;
    }
}