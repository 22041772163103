/*------------------------*/
/* Стилизуем радио-кнопки */
/*------------------------*/
input[type="radio"] {
    display: none;
}

input[type="radio"] + label {
    cursor: pointer;
    position: relative;
}

input[type="radio"]:checked + label:before {
    background: black;
}

/* Создаем псевдополе */
input[type="radio"] + label:before {
    cursor: pointer;
    content: "";
    height: 18.7px;
    width: 18.7px;
    display: inline-block;
    border-radius: 50%;
    vertical-align: middle;
    bottom: 2px;
    margin-right: 5px;
    position: relative;
    background: black;
    border: none;
}

/* Создаем элемент при checked */
input[type="radio"]:checked + label:before {
    background: white;
    border: 2.8px solid #313131;
    bottom: 2px;
}

/*--------------------*/
/* Стилизуем чек-бокс */
/*--------------------*/
input[type="checkbox"] {
    display: none;
}

input[type="checkbox"] + label {
    cursor: pointer;
    position: relative;
    height: 27px;
    display: inline-block;
}


/* Фон при позиции cheked */
input[type="checkbox"]:checked + label:before {
    background: white;
}

/* Создаем псевдополе */
input[type="checkbox"] + label:before {
    cursor: pointer;
    content: "";
    height: 27px;
    width: 27px;
    display: inline-block;
    border: 1px solid #A6A6A6;
    background: white;
    border-radius: 2px;
    vertical-align: middle;
    position: relative;
    bottom: 20px;
}

/* Создаем элемент при checked */
input[type="checkbox"]:checked + label:after {
    content: "";
    height: 100px;
    width: 100px;
    display: inline-block;
    position: absolute;
    left: 0px;
    top: -3px;
    background: url('../images/mark.png') no-repeat;
    background-size: 40%;
}