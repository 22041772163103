@import '../fonts/Roboto/Roboto.css';
@import '../fonts/a_PlakatCmpl/a_PlakatCmpl.css';
@import '../css/reset.css';
*,
*:before,
*:after,
*:focus,
*:active {
  box-sizing: border-box;
  outline: none !important;
}
html {
  height: 100%;
  font-size: 14px;
}
@media (max-width: 1366px) {
  html {
    font-size: 14px;
  }
}
body {
  font-family: 'Roboto', Verdana, sans-serif;
  font-size: 1rem;
  height: 100%;
  color: black;
}
#wrapper {
  background: url('../images/wrapper-bg.png');
  background-repeat: no-repeat;
  background-position: top right;
}
#work {
  scroll-behavior: smooth;
}
img {
  max-width: 100%;
}
a {
  text-decoration: none;
}
p {
  text-align: justify;
  margin-bottom: 10px;
}
header {
  min-height: 55px;
  background: #292728;
  padding: 0 90px;
}
@media (max-width: 992px) {
  header {
    padding: 0;
  }
}
footer {
  color: white;
  min-height: 200px;
  margin-top: 40px;
  background: #292728;
  padding: 38px 105px 20px;
}
@media (max-width: 1366px) {
  footer {
    padding: 38px 80px 20px;
  }
}
@media (max-width: 992px) {
  footer {
    padding: 20px 0;
  }
}
.center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
/* Служебные классы */
@media (max-width: 992px) {
  .break {
    display: none;
  }
}
.breakInvert {
  display: none;
}
@media (max-width: 992px) {
  .breakInvert {
    display: block;
    margin-bottom: 5px;
  }
}
.white {
  color: white;
}
.orange {
  color: #FE8A17;
}
@media (max-width: 992px) {
  .customMT-5 {
    display: block;
    margin-top: 5px;
  }
}
.customMT-50 {
  margin-top: 50px;
}
.customMT-30 {
  margin-top: 30px;
}
.customML-15 {
  margin-left: 15px;
}
.customMT-98 {
  margin-top: 98px;
}
@media (max-width: 992px) {
  .customMT-98 {
    margin-top: 60px;
  }
}
@media (max-width: 992px) {
  .customMT-55 {
    margin-top: 55px;
  }
}
.customMB-30 {
  margin-bottom: 30px;
}
.crane {
  background: #F6F6F6;
  background-image: url('../images/crane.png');
  background-repeat: no-repeat;
  background-position: top right;
  background-size: auto 300px;
  max-width: 996px;
  min-height: 334px;
  display: block;
  margin-right: auto;
  margin-left: auto;
}
.displayNone {
  display: none !important;
}
.customPT-5 {
  padding-top: 5px !important;
}
.customPT-10 {
  padding-top: 10px !important;
}
.visibleMobile {
  display: none;
}
@media (max-width: 992px) {
  .visibleMobile {
    display: flex;
  }
}
.hiddenMobile {
  display: flex;
}
@media (max-width: 992px) {
  .hiddenMobile {
    display: none;
  }
}
/*------------------------*/
/* Стилизуем радио-кнопки */
/*------------------------*/
input[type="radio"] {
  display: none;
}
input[type="radio"] + label {
  cursor: pointer;
  position: relative;
}
input[type="radio"]:checked + label:before {
  background: black;
}
/* Создаем псевдополе */
input[type="radio"] + label:before {
  cursor: pointer;
  content: "";
  height: 18.7px;
  width: 18.7px;
  display: inline-block;
  border-radius: 50%;
  vertical-align: middle;
  bottom: 2px;
  margin-right: 5px;
  position: relative;
  background: black;
  border: none;
}
/* Создаем элемент при checked */
input[type="radio"]:checked + label:before {
  background: white;
  border: 2.8px solid #313131;
  bottom: 2px;
}
/*--------------------*/
/* Стилизуем чек-бокс */
/*--------------------*/
input[type="checkbox"] {
  display: none;
}
input[type="checkbox"] + label {
  cursor: pointer;
  position: relative;
  height: 27px;
  display: inline-block;
}
/* Фон при позиции cheked */
input[type="checkbox"]:checked + label:before {
  background: white;
}
/* Создаем псевдополе */
input[type="checkbox"] + label:before {
  cursor: pointer;
  content: "";
  height: 27px;
  width: 27px;
  display: inline-block;
  border: 1px solid #A6A6A6;
  background: white;
  border-radius: 2px;
  vertical-align: middle;
  position: relative;
  bottom: 20px;
}
/* Создаем элемент при checked */
input[type="checkbox"]:checked + label:after {
  content: "";
  height: 100px;
  width: 100px;
  display: inline-block;
  position: absolute;
  left: 0px;
  top: -3px;
  background: url('../images/mark.png') no-repeat;
  background-size: 40%;
}
@media (max-width: 992px) {
  .logo {
    display: none;
    height: 40px;
    margin-top: 6px;
  }
}
.logoResize {
  margin-top: 5px;
  height: 45px;
}
.addressMobile {
  font-size: 0.85rem;
  color: white;
  text-align: center;
  padding-top: 2px;
  margin-bottom: 0;
}
.phone {
  font-family: 'Roboto';
  font-size: 1.57rem;
  text-align: left;
  font-weight: bold;
  color: #FE8A17;
  margin-top: 15px;
}
@media (max-width: 992px) {
  .phone {
    font-size: 1.28rem;
    text-align: center;
  }
}
.phone_header {
  text-align: right;
  margin-top: 5px;
}
@media (max-width: 992px) {
  .phone_header {
    display: none;
  }
}
.buttonMenu {
  margin-top: 15px;
  float: right;
}
.address {
  font-family: 'Roboto';
  padding-top: 31px;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 20px;
  color: #FE8A17;
  text-align: right;
  margin: 0;
  height: 20px;
}
.phoneFirst {
  font-family: 'a_PlakatCmpl';
  padding-top: 29px;
  margin: 0;
  font-size: 1.57rem;
  font-style: normal;
  line-height: 20px;
  text-align: right;
  color: #FE8A17;
}
@media (max-width: 992px) {
  .phoneFirst {
    font-size: 1.28rem;
    padding-top: 15px;
  }
}
/* Header */
@media (min-width: 992px) {
  .headerFixed {
    position: fixed;
    z-index: 9999;
    max-height: 70px;
    top: 0;
    left: 0;
    width: 100%;
    animation-name: animHeader;
    animation-duration: 1s;
  }
}
@keyframes animHeader {
  0% {
    opacity: 0;
    top: -127px;
  }
  100% {
    opacity: 1;
    top: 0;
  }
}
/* TOPMENU */
.topmenu {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 9px;
}
@media (max-width: 992px) {
  .topmenu {
    position: absolute;
    width: 60%;
    height: 568px;
    background-color: rgba(0, 0, 0, 0.8);
    margin: 0;
    top: 0;
    left: 0;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    transform: translateX(-100%);
    padding-left: 10px;
    padding-right: 10px;
  }
}
.topmenu_active {
  transform: translateX(0);
}
@media (max-width: 992px) {
  .topmenu__item {
    background: black;
    margin: 5px 0;
    width: 100%;
    height: 45px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
}
.topmenu__link {
  color: white;
  font-size: 1rem;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  line-height: 20px;
  text-transform: uppercase;
}
.topmenu__link:hover {
  color: #FE8A17;
}
@media (max-width: 992px) {
  .topmenu__link {
    padding-left: 15px;
    height: 100%;
    color: #FE8A17;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
/* PROMO блок */
.promo {
  background: url('../images/promo.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  height: 644px;
  margin-bottom: 75px;
  overflow: visible;
}
@media (max-width: 992px) {
  .promo {
    background: url('../images/mobile-promo.jpg');
    background-repeat: no-repeat;
    background-size: contain;
    height: 425px;
    margin-bottom: 20px;
  }
}
.promo__block {
  display: block;
  margin: 0 auto;
  max-width: 887px;
  height: 685px;
  background: url('../images/promo-bg.png');
  background-size: contain;
  padding-top: 68px;
}
@media (max-width: 992px) {
  .promo__block {
    background: url('../images/mobile-promo-bg.png');
    background-size: cover;
    background-position: center;
    padding-top: 35px;
    height: 425px;
  }
}
.promo__logo {
  height: 85px;
  display: block;
  margin: 0 auto;
}
@media (max-width: 992px) {
  .promo__logo {
    height: 51px;
  }
}
.promo__slogan {
  font-family: 'a_PlakatCmpl';
  text-align: center;
  margin: 0;
}
.promo__slogan_first {
  padding: 39px 65px 0;
  font-size: 2.57rem;
  line-height: 40px;
  text-transform: uppercase;
  color: #FE8A17;
  margin: 0;
}
@media (max-width: 992px) {
  .promo__slogan_first {
    padding: 34px 15px 0;
    font-size: 1.78rem;
    line-height: 30px;
  }
}
.promo__slogan_second {
  padding: 65px 150px 0;
  font-size: 2.5rem;
  line-height: 35px;
  text-align: center;
  color: #FFFFFF;
}
@media (max-width: 992px) {
  .promo__slogan_second {
    padding: 40px 15px 0;
    font-size: 1.43rem;
    line-height: 21.5px;
  }
}
.promo__slogan_third {
  font-family: 'Roboto';
  padding: 71px 180px 0;
  font-style: normal;
  font-weight: bold;
  font-size: 2.5rem;
  line-height: 36px;
  text-transform: uppercase;
  color: #FE8A17;
  margin: 0;
}
@media (max-width: 992px) {
  .promo__slogan_third {
    padding: 40px 15px 0;
    font-size: 1.43rem;
    line-height: 21.5px;
  }
}
/* Блок преимуществ */
.advantage__targetArrow {
  display: block;
  margin: 35px auto 0;
}
@media (max-width: 992px) {
  .advantage__targetArrow {
    margin-top: 20px;
  }
}
.advantage__title {
  font-family: 'a_PlakatCmpl';
  font-size: 2.57rem;
  margin-top: 25px;
  line-height: 35px;
  text-align: center;
}
@media (max-width: 992px) {
  .advantage__title {
    font-size: 1.78rem;
  }
}
.advantage__signature {
  font-family: 'Circe';
  font-style: normal;
  font-weight: normal;
  font-size: 1.14rem;
  line-height: 24px;
  text-align: center;
  color: #000000;
  margin: 10px 0 0 0;
}
.advantage__num {
  background: url('../images/brackets.svg');
  background-repeat: no-repeat;
  font-family: 'Roboto';
  font-weight: bold;
  display: block;
  font-size: 100px;
  width: 95px;
  height: 112px;
  margin: 0 auto;
  position: relative;
  z-index: 99;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
@media (max-width: 992px) {
  .advantage__num {
    font-size: 80px;
    width: 71px;
    height: 83px;
    background: url('../images/mobile-brackets.svg');
  }
}
/* Слоганы */
.advantage__slogan {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 1.79rem;
  text-align: left;
  position: relative;
  z-index: 99;
}
.advantage__slogan_first {
  font-size: 1.79rem;
  line-height: 30px;
  text-transform: uppercase;
}
@media (max-width: 992px) {
  .advantage__slogan_first {
    font-size: 1.29rem;
    line-height: 25px;
  }
}
.advantage__slogan_second {
  font-size: 1.1rem;
  line-height: 29px;
}
@media (max-width: 992px) {
  .advantage__slogan_second {
    font-size: 1.29rem;
    line-height: 20px;
  }
}
.advantage__slogan_third {
  font-size: 1.1rem;
  line-height: 20px;
  color: #FE9418;
}
.advantage__img {
  position: relative;
  z-index: 99;
}
/* Геометрические фигуры */
.advantage__geometry {
  position: relative;
  overflow: visible;
}
.advantage__triangle {
  position: absolute;
  top: -150px;
  left: 150px;
}
@media (max-width: 992px) {
  .advantage__triangle {
    width: 150px;
    top: -49px;
    left: 214px;
  }
}
.advantage__triangle2 {
  position: absolute;
  z-index: 9;
  top: -40px;
  left: -10px;
}
.advantage__square {
  position: absolute;
  z-index: 9;
  top: -40px;
  left: 140px;
}
/* Блок окупаемости */
.profit {
  margin-top: 158px;
  min-height: 540px;
  background: url('../images/iron.jpg');
  padding: 0 135px;
}
@media (max-width: 992px) {
  .profit {
    margin-top: 0;
    padding: 0 15px;
  }
}
.profit__poster {
  font-family: 'a_PlakatCmpl';
  background: #FE8A17;
  width: 708px;
  height: 156px;
  display: block;
  margin: 0 auto;
  top: -87px;
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.25);
  padding-top: 34px;
  position: relative;
  z-index: 9;
}
@media (max-width: 992px) {
  .profit__poster {
    padding: 20px 20px 0 20px;
    height: 174px;
    top: 0;
    left: -15px;
    width: calc(100% + 30px);
  }
}
.profit__call {
  font-size: 2.5rem;
  line-height: 35px;
  text-align: center;
}
@media (max-width: 992px) {
  .profit__call {
    font-size: 1.78rem;
    margin-bottom: 5px;
  }
}
.profit__money {
  font-size: 35px;
  line-height: 35px;
  text-align: center;
  color: white;
  font-size: 2.5rem;
  text-transform: uppercase;
  margin-bottom: 0;
}
@media (max-width: 992px) {
  .profit__money {
    font-size: 1.78rem;
  }
}
.profit__angle {
  position: absolute;
  top: -14px;
  right: -14px;
  z-index: 99;
}
.profit__angle_right {
  left: -14px;
  transform: rotate(0.75turn);
}
@media (max-width: 992px) {
  .profit__angle {
    display: none;
  }
}
.profit__arrow {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
@media (max-width: 992px) {
  .profit__arrow img {
    transform: rotate(90deg);
  }
}
.profit__slogan {
  color: white;
  text-transform: uppercase;
  background: url('../images/profit-brackets.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 189px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 992px) {
  .profit__slogan {
    background: url('../images/mobile-profit-brackets.png');
    background-size: cover;
    background-repeat: no-repeat;
    height: 289px;
    padding: 0 20px;
  }
}
@media (max-width: 992px) {
  .profit__slogan_mobile {
    background: url('../images/mobile-profit-brackets-2.png');
    background-size: cover;
    background-repeat: no-repeat;
    height: 191px;
    padding: 0 20px;
  }
}
.profit__textSmall {
  font-size: 1.5rem;
  text-align: center;
}
.profit__textMiddle {
  font-size: 2rem;
  text-align: center;
}
.profit__textBig {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
}
.profit__signature {
  margin-top: 60px;
  font-family: 'a_PlakatCmpl';
  color: white;
  font-size: 2.5rem;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media (max-width: 992px) {
  .profit__signature {
    margin-top: 48px;
    font-size: 1.78rem;
    display: block;
  }
  .profit__signature p {
    text-align: center;
  }
}
/* Блок с картой Яндекс*/
@media (min-width: 992px) {
  .mapbox {
    height: 650px;
  }
}
.mapbox__title {
  margin-top: 94px;
  font-family: 'a_PlakatCmpl';
  font-size: 2.5rem;
  text-align: center;
  position: relative;
  z-index: 999;
}
@media (max-width: 992px) {
  .mapbox__title {
    font-size: 1.78rem;
    margin-top: 48px;
    padding: 0 10px;
  }
}
.mapbox__slogan {
  color: #FE8A17;
  font-size: 1.78rem;
  font-weight: bold;
  text-align: center;
  position: relative;
  z-index: 999;
}
@media (max-width: 992px) {
  .mapbox__slogan {
    font-size: 1.14rem;
    padding: 0 40px;
  }
}
.mapbox__map {
  width: 1040px;
  display: block;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  top: -90px;
}
@media (max-width: 992px) {
  .mapbox__map {
    display: none;
  }
}
@media (min-width: 992px) {
  .mapbox__map_mobile {
    display: none;
  }
}
@media (max-width: 992px) {
  .mapbox__map_mobile {
    display: block;
    top: -44px;
  }
}
/* Предложение */
.offer {
  overflow: hidden;
}
.offer__slogan {
  color: white;
  height: 189px;
  width: 656px;
  background: url('../images/offer-brackets.png');
  padding: 21px 24px;
  display: block;
  margin: 40px auto 0;
  position: relative;
  z-index: 9;
}
@media (max-width: 992px) {
  .offer__slogan {
    background: url('../images/offer-brackets-mobile.png');
    background-position: center;
    background-repeat: no-repeat;
    padding: 21px 28px;
    width: 100%;
    height: 204px;
  }
}
.offer__subSlogan {
  background: #3A3737;
  height: 100%;
  padding: 20px 30px;
}
.offer__subSlogan p {
  font-family: 'a_PlakatCmpl';
  font-size: 2.14rem;
  text-align: center;
}
@media (max-width: 992px) {
  .offer__subSlogan {
    padding: 20px 14px;
  }
  .offer__subSlogan p {
    font-size: 1.78rem;
    line-height: 30px;
  }
}
.offer__textFirst {
  font-size: 1.78rem;
}
@media (max-width: 992px) {
  .offer__textFirst {
    margin-bottom: 5px;
  }
}
.offer__free {
  padding-top: 140px;
  margin: 0 auto;
  position: relative;
  top: -94px;
  z-index: 1;
}
@media (min-width: 992px) {
  .offer__free {
    background: #F6F6F6;
    background-image: url('../images/crane.png');
    background-repeat: no-repeat;
    background-position: top right;
    background-size: auto 300px;
    max-width: 996px;
    min-height: 334px;
    display: block;
    margin-right: auto;
    margin-left: auto;
  }
}
@media (max-width: 992px) {
  .offer__free {
    padding: 120px 0 20px;
    margin-left: 15px;
    margin-right: 15px;
    background: #F6F6F6;
  }
}
.offer__crane {
  position: absolute;
  top: -20px;
  left: 130px;
}
@media (min-width: 992px) {
  .offer__crane {
    display: none;
  }
}
.offer__text {
  font-family: 'a_PlakatCmpl';
  font-size: 2.5rem;
  text-align: center;
}
@media (max-width: 992px) {
  .offer__text {
    font-size: 1.78rem;
    line-height: 25px;
  }
}
.offer__text_phone {
  padding: 0 40px;
}
.offer__signature {
  text-align: center;
  font-size: 1.42rem;
}
@media (max-width: 992px) {
  .offer__signature_last {
    display: none;
  }
}
@media (max-width: 992px) {
  .offer__signature {
    font-size: 1.14rem;
    line-height: 18.5px;
    padding: 0 50px;
  }
}
/* Блок о сайте */
.sitebox {
  background: black;
  padding: 0 57px;
}
@media (max-width: 992px) {
  .sitebox {
    padding: 0;
    margin-right: -0.75rem;
    margin-left: -0.75rem;
  }
}
.sitebox__content {
  background-blend-mode: multiply;
  background-image: url('../images/site.jpg');
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgba(0, 0, 0, 0.8);
  height: 428px;
  padding-top: 57px;
}
@media (max-width: 992px) {
  .sitebox__content {
    padding-top: 20px;
  }
}
.sitebox__slogan {
  color: white;
  margin-top: 0 !important;
}
@media (max-width: 992px) {
  .sitebox__slogan {
    width: 100%;
  }
}
@media (max-width: 992px) {
  .sitebox__text {
    display: none;
  }
}
.sitebox__text_mobile {
  font-family: 'a_PlakatCmpl';
  color: #FE8A17;
  display: block;
  font-size: 1.78rem;
  font-weight: bold;
  padding: 0 40px;
  line-height: 30px;
  text-align: center;
}
@media (min-width: 992px) {
  .sitebox__text_mobile {
    display: none;
  }
}
.sitebox__row {
  height: 245px;
}
@media (max-width: 992px) {
  .sitebox__row {
    height: 100%;
  }
}
/* Описание */
.sitebox__desc {
  background: white;
  padding: 30px;
  position: relative;
  top: -70px;
  left: 30px;
}
@media (max-width: 992px) {
  .sitebox__desc {
    left: -20px;
    padding: 30px 20px 30px 30px;
  }
}
.sitebox__title {
  font-size: 1.64rem;
  font-weight: 900;
}
.sitebox__list {
  padding-left: 30px;
  list-style-type: '— ';
}
.sitebox__item {
  margin-top: 10px;
  font-weight: bold;
  font-size: 1.25rem;
}
.sitebox__img {
  position: relative;
  top: -110px;
}
@media (max-width: 992px) {
  .sitebox__img {
    top: -70px;
  }
}
.sitebox__sigBox {
  margin-top: 28px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.sitebox__signature {
  font-family: 'a_PlakatCmpl';
  font-size: 2.1rem;
  color: #FE9418;
  margin-bottom: 0;
  line-height: 30px;
}
@media (max-width: 992px) {
  .sitebox__signature {
    font-size: 1.78rem;
  }
}
.sitebox__arrow {
  margin-left: 32px;
}
@media (max-width: 992px) {
  .sitebox__arrow {
    margin-left: 0;
    position: relative;
    left: 20px;
  }
}
/* Главный слоган */
.mainSlogan {
  background: url('../images/slogan-brackets.png');
  background-repeat: no-repeat;
  background-position: center;
  width: 858px;
  height: 227px;
  margin: 77px auto 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media (max-width: 992px) {
  .mainSlogan_main {
    background: url('../images/slogan-brackets-main-mobile.png') !important;
    margin-top: 10px;
    background-repeat: no-repeat !important;
    background-position: center !important;
    height: 375px;
  }
}
.mainSlogan p {
  font-family: 'a_PlakatCmpl';
  font-size: 1.8rem;
}
@media (max-width: 992px) {
  .mainSlogan p {
    font-size: 1.78rem;
    padding: 0 20px;
    line-height: 30px;
    text-align: center;
    margin-bottom: 0;
  }
}
@media (max-width: 992px) {
  .mainSlogan {
    background: url('../images/slogan-brackets-mobile.png');
    background-repeat: no-repeat;
    background-position: center;
    padding: 0 30px;
    width: 100%;
  }
}
/* Платеж */
.payment {
  margin-top: 81px;
  background: url('../images/iron.jpg');
  min-height: 359px;
  padding-bottom: 60px;
}
.payment__pay {
  width: 514px;
  height: 137px;
  padding-top: 15px;
  background: #FE8A17;
  display: block;
  margin: 0 auto;
  position: relative;
  top: -15px;
  font-family: 'a_PlakatCmpl';
  font-size: 2.58rem;
  text-align: center;
}
.payment__pay p {
  text-align: center;
}
@media (max-width: 992px) {
  .payment__pay p {
    font-size: 2.14rem;
    padding: 0 40px;
    line-height: 32px;
  }
}
@media (max-width: 992px) {
  .payment__pay p:last-child {
    margin-top: 15px;
  }
}
@media (max-width: 992px) {
  .payment__pay {
    padding-top: 25px;
    height: 189px;
    width: calc(100% - 30px);
    margin: 15px;
  }
}
.payment__signature {
  width: 688px;
  min-height: 100px;
  display: block;
  margin: 60px auto 0;
  padding: 20px;
  position: relative;
  color: white;
  font-family: 'a_PlakatCmpl';
  font-size: 2.58rem;
}
.payment__signature p {
  text-align: center;
}
@media (max-width: 992px) {
  .payment__signature {
    font-size: 1.78rem;
    line-height: 30px;
    margin-top: 33px;
    width: calc(100% - 60px);
    margin-right: 30px;
    margin-left: 30px;
  }
}
.payment__angle {
  position: absolute;
}
.payment__angle_1 {
  top: 0;
  left: 0;
  transform: rotate(90deg);
}
.payment__angle_2 {
  top: 0;
  right: 0;
  transform: rotate(180deg);
}
.payment__angle_3 {
  bottom: 0;
  left: 0;
  transform: rotate(0deg);
}
.payment__angle_4 {
  bottom: 0;
  right: 0;
  transform: rotate(270deg);
}
/* Проверьте город */
.checkCity {
  overflow: hidden;
}
.checkCity__title {
  font-family: 'a_PlakatCmpl';
  font-size: 2.58rem;
  text-align: center;
}
@media (max-width: 992px) {
  .checkCity__title {
    font-size: 1.78rem;
    padding: 0 15px;
  }
}
.checkCity__poster {
  position: relative;
  z-index: 1;
  margin: 66px auto 0;
  padding-top: 45px;
  padding-bottom: 60px;
}
@media (min-width: 992px) {
  .checkCity__poster {
    background: #F6F6F6;
    background-image: url('../images/crane.png');
    background-repeat: no-repeat;
    background-position: top right;
    background-size: auto 300px;
    max-width: 996px;
    min-height: 334px;
    display: block;
    margin-right: auto;
    margin-left: auto;
  }
}
@media (max-width: 992px) {
  .checkCity__poster {
    background: #F6F6F6;
    width: calc(100% - 30px);
    margin-right: 15px;
    margin-left: 15px;
  }
}
.checkCity__crane {
  position: absolute;
  top: 70px;
  left: 110px;
}
@media (min-width: 992px) {
  .checkCity__crane {
    display: none;
  }
}
/* FOOTER */
.footer__title {
  text-transform: uppercase;
  color: #FE8A17;
  font-size: 1.28rem;
}
@media (max-width: 992px) {
  .footer__title {
    margin-top: 20px;
  }
}
.footer__menu {
  padding-left: 0;
  margin-top: 30px;
}
@media (max-width: 992px) {
  .footer__menu {
    margin-top: 15px;
  }
}
.footer__item {
  margin-top: 10px;
  font-size: 1.28rem;
}
.footer__link {
  color: white;
  font-size: 1.28rem;
}
.footer__link:hover {
  color: #FE8A17;
}
.footer__signature {
  color: #FE8A17;
}
@media (max-width: 992px) {
  .footer__signature {
    margin-top: 15px;
    text-align: center;
  }
}
.footer__logo {
  display: block;
  margin: 0 auto;
}
@media (max-width: 992px) {
  .footer__logo {
    margin-top: 30px;
    padding: 0 9.5px;
  }
}
.footer__aimart {
  margin-top: 20px;
  float: right;
}
@media (max-width: 992px) {
  .footer__aimart {
    width: 100%;
    margin: 40px auto 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
.footer__copyRight {
  font-size: 0.71rem;
  line-height: 18px;
  margin-bottom: 0;
}
@media (max-width: 992px) {
  .footer__copyRight {
    padding: 0 9.5px;
  }
}
.cityBox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
@media (max-width: 992px) {
  .cityBox {
    align-items: center;
  }
}
.cityBox__link {
  padding: 5px 0;
  display: inline-block;
  text-align: left;
  color: #FE8A17;
}
@media (max-width: 992px) {
  .cityBox__link {
    padding: 5px 10px;
    margin-top: 10px;
  }
}
