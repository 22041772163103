.center {
    display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

/* Служебные классы */
.break {
    @media @mobile {
        display: none;
    }
}

.breakInvert {
    display: none;
    @media @mobile {
        display: block;
        margin-bottom: 5px;
    }
}

.white {
	color: white;
}

.orange {
	color: @orange;
}

.customMT-5 {
    @media @mobile {
        display: block;
        margin-top: 5px;
    }
}

.customMT-50 {
    margin-top: 50px;
}

.customMT-30 {
    margin-top: 30px;
}

.customML-15 {
    margin-left: 15px;
}

.customMT-98 {
	margin-top: 98px;
    @media @mobile {
        margin-top: 60px;
    }
}

.customMT-55 {
    @media @mobile {
        margin-top: 55px;
    }
}

.customMB-30 {
    margin-bottom: 30px;
}

.crane {
	background: #F6F6F6;
    background-image: url('../images/crane.png');
    background-repeat: no-repeat;
    background-position: top right;
    background-size: auto 300px;
    max-width: 996px;
    min-height: 334px;
    display: block;
    margin-right: auto;
    margin-left: auto;
}

.displayNone {
    display: none !important;
}

.customPT-5 {
    padding-top: 5px !important;
}

.customPT-10 {
    padding-top: 10px !important;
}

.visibleMobile {
    display: none;
    @media @mobile {
        display: flex;
    }
}

.hiddenMobile {
    display: flex;
    @media @mobile {
        display: none;
    }
}